import _ from 'lodash';
import moment from 'moment';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Slider from "react-slick";
import { getListJob } from '../../redux/actions/job';
import { useRouter } from "next/router";
import { Button, Form, Input, Select, Upload, message, Tooltip } from 'antd';
import { formatMoney, trimTextUnder, formatDate, formatSalary, checkTypeFileDoc, urlValidate } from '@/lib/common';
import MetaSeo from '@/components/MetaSeo';
import { uploadOneFile } from '@/redux/actions/upload';
import { submitForm } from '@/redux/actions/submitForm';
const { Option } = Select;

export default function Home(props) {
  const {
    description,
    title,
    salary_unit,
    hot,
    fulltime,
    experience,
    address_detail,
    salary_end,
    salary_start,
    level_detail,
    position_detail,
    tag,
    start_date,
    end_date,
    image,
    file_jd,
    seo_title,
    seo_description,
  } = props?.data || {};
  const defaultConfigs = {
    header: {
      textAlign: 'left',
    },
    image: {
      img: {},
      figure: {
        backgroundColor: 'aliceblue',
        border: '1px solid #eee',
      },
      figcaption: {
        borderRadius: '5px',
      },
    },
    video: {
      video: {},
      figure: {
        backgroundColor: 'aliceblue',
        border: '1px solid #eee',
      },
      figcaption: {
        borderRadius: '5px',
      },
    },
    embed: {
      video: {},
      figure: {
        justifyContent: 'center',
      },
      figcaption: {
        borderRadius: '5px',
      },
    },
    paragraph: {
      textAlign: 'left',
      cursor: 'default',
    },
    list: {
      textAlign: 'left',
    },
    checklist: {
      container: {},
      item: {},
      checkbox: {},
      label: {},
    },
    table: {
      table: {},
      tr: {},
      th: {},
      td: {},
    },
    quote: {
      container: {},
      content: {},
      author: {
        fontWeight: 'bold',
      },
      message: {
        textAlign: 'left',
      },
    },
    codeBox: {
      container: {
        width: '100%',
      },
      code: {
        boxSizing: 'border-box',
      },
    },
    warning: {
      container: {
        width: '100%',
      },
      icon: {
        width: '30px',
      },
      title: {
        fontWeight: 500,
      },
      message: {
        fontSize: '14px',
      },
    },
    delimiter: {
      container: {},
      svg: {},
      path: {
        fill: '#231F20',
      },
    },
  };
  const current = moment();
  // open popup apply
  const [show, setShow] = useState('none');
  const hiddenFileInput = useRef(null);
  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const [showForm, setShowForm] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [share, setShare] = useState('');
  const [valueSearch, setValueSearch] = useState('');
  const [optionJob, setOptionJob] = useState('');

  const formRefSearch = useRef(null);
  const formRef = useRef(null);
  const router = useRouter();
  const data = props.data;
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden='true'
      aria-disabled={currentSlide === 0 ? true : false}
      type='button'
    >
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden='true'
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type='button'
    >
    </button>
  );

  const settingSlider = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    autoplay: false,
    speed: 600,
    // adaptiveHeight: true,
    // variableWidth: true,
    responsive: [

      {
        breakpoint: 991.5,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          // variableWidth: true,
          arrows: true,
          prevArrow: <SlickArrowLeft />,
          nextArrow: <SlickArrowRight />,
        },
      }, {
        breakpoint: 767.5,
        settings: {
          autoplay: true,
          speed: 900,
          slidesToShow: 2,
          slidesToScroll: 1,
          // variableWidth: true,
          arrows: true,
          prevArrow: <SlickArrowLeft />,
          nextArrow: <SlickArrowRight />,
        },
      }
    ],
  };

  const dispatch = useDispatch();

  useEffect(() => {
    getListJob({ type: 'home' }, dispatch);
  }, []);

  let { listJob } = useSelector(state => {
    return {
      listJob: state.job.listJob,
    };
  }, shallowEqual);

  if (listJob && listJob.rows && listJob.rows.length === 2) {
    listJob.rows = [...listJob.rows, ...listJob.rows];
  }
  if (listJob && listJob.rows && listJob.rows.length === 3) {
    listJob.rows = [...listJob.rows, ...listJob.rows];
  }
  if (listJob && listJob.rows && listJob.rows.length === 1) {
    listJob.rows = [...listJob.rows, ...listJob.rows, ...listJob.rows, ...listJob.rows];
  }

  const onkeyPress = (e) => {
    if (e.keyCode === 13) {
      onSearchDelayed();
    }
  };
  const getDataOption = (e) => {
    setOptionJob(e.target.value);
  };
  const selectFile = e => {
    setSelectedFile(e.target.files[0]);
  };
  const OnSubmit = async () => {
    await formRef?.current?.validateFields();
    let {
      name,
      email,
      mobile,
      linkUrl,
      purpose,
    } = formRef.current.getFieldsValue();
    let file = '';
    if (selectedFile) {

      let newUploadFile = new FormData();

      await newUploadFile.append('file', selectedFile);

      file = await uploadOneFile(newUploadFile);

    }
    if (!purpose) {
      purpose = props.address_detail;
    }
    let dataPush = {
      name,
      email,
      mobile,
      url: file,
      address: purpose,
      other_data: {
        profile: linkUrl
      },
      job: title,
      type: 'apply'
    };

    message.open({
      key: 'load',
      type: 'loading',
      content: 'Đang gửi....',
    });
    const res = await submitForm(dataPush);

    await message.destroy('load');

    if (res.code !== 200) {
      // formRef.current.resetFields();
      // setSelectedFile(null);
      // setShow('none')
      message.error(res.message, 2);

    } else {
      formRef.current.resetFields();
      setSelectedFile(null);
      setShow('none');
      message.success("Gửi đơn ứng tuyển thành công!", 2);
    }
  };
  const regexPhoneNumber = /^((\+)84|0)[1-9](\d{2}){4}$/;

  const validateEmailWithoutSpecial = email => {
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    let re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return re.test(email.toLowerCase());
  };
  const urlValidate = url => {
    let re = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

    return re.test(url);
  };
  const trimInput = (key, e) => {
    let { value } = e.target;
    formRef.current.setFieldsValue({ [key]: value.trim() });
  };
  const onSubmitDelay = _.debounce(() => OnSubmit(), 100);

  const onSearchDelayed = _.debounce(() => search(), 100);
  const search = () => {
    const keyword = valueSearch ? 'valueSearch=' + valueSearch : '';
    // const data = await getListJob({ id: optionJob, title: valueSearch }, dispatch);
    router.push('/jobs?' + keyword + '&optionJob=' + optionJob);

  };
  useEffect(() => {
    if (show === 'block') {
      document.body.style.position = 'fixed';
    }
    else {
      document.body.style.position = 'static';
    }
  }, [show]);

  const dataSeo = {
    url: 'https://tcom.vn',
    type: 'website',
    title: "Cơ hội nghề nghiệp | TCOM Corporation",
    description: 'Tcom tech for life',
    domain: 'tcom.vn',
    image: 'https://tcom.vn/assets/images/about/gallery-2.png',
    card: 'summary_large_image',
    keywords: 'Artificial Intelligence, Blockchain, AR/VR, Livestreaming, Tuyển dụng IT, Kỹ sư phần mềm, NodeJS, iOS, Android, Python, BrSE, BA, Comtor'
  };
  return (
    <>
      <MetaSeo dataSeo={dataSeo} />
      <head>
        <title>Tuyển dụng Tcom</title>
      </head>
      <div className='main-content home-content'>
        <div className='sc-top'>
          {/*  */}
          <div className='bs-search'>
            <div className='bs-ttl'>
              TCOM - Công ty công nghệ đa lĩnh vực <span className='br-sp'>hàng đầu Việt Nam</span>
            </div>
            <div className='bs-form'>
              <Form ref={formRefSearch}>
                <div className='bs-select'>
                  <select name='position' id='j-position' className='custom-select sources' placeholder='Vị trí' onChange={(e) => getDataOption(e)} value={optionJob}>
                    <option value='' selected>Vị trí</option>
                    <option value={1}>Bộ phận TSOFT</option>
                    <option value={2}>Bộ phận Platform</option>
                    <option value={3}>Bộ phận Mobile</option>
                    <option value={4}>Bộ phận AIoT</option>
                    <option value={5}>Bộ phận QA/QC</option>
                    <option value={6}>Bộ phận Design</option>
                    <option value={7}>Bộ phận Kinh doanh</option>
                    <option value={8}>Bộ phận Truyền thông và Maketing</option>
                  </select>
                </div>
                <div className='bs-select'>
                  <Input

                    value={valueSearch}
                    onChange={(e) => { setValueSearch(e.target.value); }}
                    style={{ fontSize: '20px' }}
                    placeholder='Keyword'
                    type='text'
                    onKeyDown={onkeyPress}
                  />
                </div>
                <input type='button' value={`Tìm Kiếm`} onClick={onSearchDelayed} style={{ cursor: 'pointer' }} />
              </Form>
            </div>
          </div>


          <div className='bs-slide' >
            <Slider className='slider' {...settingSlider}>
              {listJob?.rows?.map((item, index) => {
                return (
                  <div className='item soft' key={`Slider-listJob-${index}`}>
                    <div className='under'>{trimTextUnder(item?.text_under)}</div>
                    <div className='item-ttl'>
                      {item?.title}
                    </div>
                    <div className='item-content' style={{ position: 'relative', zIndex: '2' }}>
                      <div className='item-desc'>
                        <ul>
                          <li>
                            <span className='left'>Lương: </span>
                            <span className='right'>
                              {formatSalary(item)}
                              {/* {item?.salary_unit === 2 ? '$' : ''} {item?.salary_start === item?.salary_end ? `${formatMoney(item?.salary_start)}` : `${formatMoney(item?.salary_start)} - ${formatMoney(item?.salary_end)}`} {item.salary_unit === 1 ? 'VNĐ' : (item?.salary_unit === 3 ? 'Triệu' : '')} */}
                            </span>
                          </li>
                          <li>
                            <span className='left'>Địa điểm: </span>
                            <span className='right'>{item?.address_detail}</span>
                          </li>
                          <li>
                            <span className='left'>Hạn cuối: </span>
                            <span className='right'>
                              {moment(item.end_date).isAfter(current) ? formatDate(item) : 'Hết hạn'}
                              {/* {formatDate(item)} */}
                              {/* {item?.start_date ? moment(item?.start_date).format('DD/MM') : ''} - {item.end_date ? moment(item?.end_date).format('DD/MM') : ''} */}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className='item-button'>
                        <div style={{ cursor: 'pointer' }} className='item-btn-text'>
                          {/* <Link href={`/jobs/${item?.url}?action=form`}>
                            <a>Ứng tuyển ngay</a>
                          </Link> */}
                          {moment(item.end_date).isAfter(current) ? <a onClick={() => setShow('block')}>Ứng tuyển ngay</a> : <a>Ứng tuyển ngay</a>}
                        </div>
                      </div>
                      <Link href={`/jobs/${item?.url}`}>
                        <a className='btn-see'>
                          <div className='item-see'>
                            Xem chi tiết
                          </div>
                        </a>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
          {/* </div> */}

          {/* popup apply */}

          <div className='warper-popup' style={{ display: show }}>
            <div className='popup-apply' id='popup-apply'>
              <a className='hide-pop-up' onClick={() => setShow('none')}>
                <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M15.5 15.5L9.5 9.5M15.5 9.5L9.5 15.5' stroke='#818181' strokeWidth='2' strokeLinecap='round' />
                  <path d='M12.5 22.5C18.0228 22.5 22.5 18.0228 22.5 12.5C22.5 6.97715 18.0228 2.5 12.5 2.5C6.97715 2.5 2.5 6.97715 2.5 12.5C2.5 18.0228 6.97715 22.5 12.5 22.5Z' stroke='#818181' strokeWidth='2' />
                </svg>
              </a>
              <div className='container'>
                <div className='row'>
                  <div className='col left'>
                    <h2>Ứng tuyển ngay</h2>

                    <img src='/assets/images/detail/img_popup-apply.jpg' alt='' />
                  </div>
                  <div className='col right'>
                    <h2 className='title-apply-sp'>Ứng tuyển ngay</h2>

                    <div >
                      <div className='info'>
                        <Form ref={formRef}
                          initialValues={{
                            remember: true,
                          }}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          autoComplete='off'>
                          <h3>Vui lòng gửi bản mềm CV của bạn.</h3>
                          <div className='form-upload'>
                            <Form.Item
                              name='fileCV'
                              rules={[{
                                validator: (_, value) => {
                                  if (!value) {
                                    return Promise.reject('Vui lòng upload file! ');
                                  }
                                  if (selectedFile.size > 5242880) {

                                    return Promise.reject(`Vui lòng upload file nhỏ hơn 5MB!`);
                                  }
                                  const allowedExtensions = /(\.doc|\.docx|\.pdf)$/i;
                                  if (!allowedExtensions.exec(selectedFile.name)) {
                                    return Promise.reject('Vui lòng upload file đúng định dạng!');
                                  }
                                  return Promise.resolve();
                                }
                              }]}
                            >
                              <div className='box-upload'>
                                <div className='drop_box' id='drop_form'>
                                  <input
                                    ref={hiddenFileInput}
                                    type='file'
                                    onChange={e => {
                                      selectFile(e);
                                    }}
                                    hidden
                                    accept='.doc,.docx,.pdf'
                                    id='fileID'
                                    style={{ display: 'none' }}
                                  />
                                  <div className='drop-icon'>
                                    <a
                                      id='icon-upload'
                                      className={(selectedFile && selectedFile.name) === null ? "" : 'customIconUpload'}
                                      onClick={() => hiddenFileInput.current.click()}
                                    >
                                      <img
                                        src='/assets/images/detail/icon-file.svg'
                                        alt='icon file'
                                      />
                                      <h4 className='file_name_upload'>{(selectedFile && selectedFile.name) || ''}</h4>
                                      <p className=''>Tải lên các loại tệp DOC, DOCX, PDF (tối đa 5MB) <span>*</span></p>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </Form.Item>
                          </div>
                          <h3>Thông tin bổ sung, để TCOM hiểu bạn hơn</h3>
                          <Form.Item
                            name='name'
                            label='Họ và tên'
                            rules={[
                              { required: true, message: 'Vui lòng điền tên!' },
                            ]}
                          >
                            <Input
                              type='text'
                              className='form-control'
                              id='f-name'
                              placeholder='Nhập họ và tên'
                            />
                          </Form.Item>
                          <Form.Item
                            name='mobile'
                            label='Số điện thoại:'
                            rules={[{
                              validator: ((_, value) => {
                                if (!value || value.trim() === '') {
                                  return Promise.reject(
                                    'Vui lòng nhập số điện thoại!',
                                  );
                                }
                                if (value && isNaN(value)) {
                                  return Promise.reject('Vui lòng nhập định dạng số!');
                                }
                                return Promise.resolve();
                              })
                            }]}
                          >
                            <Input
                              type='tel'
                              className='form-control'
                              id='f-phone'
                              placeholder='Nhập số điện thoại'
                            />
                          </Form.Item>
                          <Form.Item
                            name='email'
                            label='Email:'
                            rules={[
                              {
                                validator: (_, value) => {
                                  if (!value || value.trim() === '') {
                                    return Promise.reject(
                                      'Vui lòng nhập email!',
                                    );
                                  }
                                  if (
                                    value &&
                                    !validateEmailWithoutSpecial(value)
                                  ) {
                                    return Promise.reject(
                                      'Vui lòng nhập email đúng định dạng!',
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input
                              type='email'
                              className='form-control'
                              id='f-mail'
                              placeholder='Nhập địa chỉ email'
                            />
                          </Form.Item>
                          <Form.Item label='Địa điểm:'
                            name='map'
                          // rules={[
                          //   { required: true, message: 'Vui lòng chọn địa điểm!' },
                          // ]}
                          >
                            <select id='f-location' className='form-control'>
                              <option>Hà Nội</option>
                              <option>Hồ Chí Minh</option>
                            </select>
                          </Form.Item>
                          <Form.Item
                            name='linkUrl'
                            label='Link Profile hoặc Portfolio:'
                            rules={[{
                              validator: ((_, value) => {
                                if (!value || value.trim() === '') {
                                  return Promise.reject(
                                    'Vui lòng nhập url!',
                                  );
                                }
                                if (value && !urlValidate(value)) {
                                  return Promise.reject('Vui lòng nhập url đúng định dạng!');
                                }
                                return Promise.resolve();

                              })
                            }]}
                          >
                            <Input
                              type='url'
                              className='form-control'
                              id='f-link'
                              placeholder='https://example.com'
                            />
                          </Form.Item>
                          <Form.Item>
                            <Button
                              style={{ width: '100%' }} type='primary' htmlType='submit'
                              className='btn-submit'
                              onClick={onSubmitDelay}
                            >
                              Gửi đơn ứng tuyển
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* end popup apply */}
        </div>
        <div className='sc-oppor'>
          <div className='container'>
            <div className='ttl-primary'>Cơ hội đang đợi bạn</div>
            <div className='oppor-box'>
              <div className='box box-txt-left bg-yellow'>
                <div className='box-text'>
                  <div className='txt-title'>
                    Cơ hội phát triển <br />bản thân
                  </div>
                  <div className='txt-desc'>
                    Bạn được tiếp cận các công nghệ mới, được tham gia vào các dự án quy mô quốc tế, được tiếp xúc với các quy trình và con người chuyên nghiệp. Trên cơ sở thực chiến đó, bạn tích lũy, nâng cấp năng lực của chính bạn.
                  </div>
                </div>
                <div className='box-image'>
                  <img src='/assets/images/img01.webp' alt='Cơ hội phát triển bản thân' />
                </div>
              </div>
              <div className='box box-txt-right bg-blue'>
                <div className='box-image'>
                  <img src='/assets/images/img02.webp' alt='Cơ hội thăng tiến rộng mở không giới hạn' />
                </div>
                <div className='box-text'>
                  <div className='txt-title'>
                    Cơ hội thăng tiến rộng <br />mở không giới hạn
                  </div>
                  <div className='txt-desc'>
                    Luôn dành cho bạn, nếu bạn khát khao vượt qua chính mình, vươn tới những vị trí cao hơn.
                  </div>
                </div>
              </div>
              <div className='box box-txt-left bg-red'>
                <div className='box-text'>
                  <div className='txt-title'>
                    Cơ hội tạo ra giá trị
                  </div>
                  <div className='txt-desc'>
                    Bạn có cơ hội tham gia vào nhiều dự án lớn, sản phẩm lớn có tính ứng dụng thực tế cao, mang lại nhiều giá trị cho khách hàng, cho cộng đồng, cho xã hội, và cho chính bản thân bạn.
                  </div>
                </div>
                <div className='box-image'>
                  <img src='/assets/images/img03.webp' alt='Cơ hội tạo ra giá trị' />
                </div>
              </div>
              <div className='box box-txt-right bg-orange'>
                <div className='box-image'>
                  <img src='/assets/images/img04.webp' alt='Cơ hội làm việc tại Nhật Bản' />
                </div>
                <div className='box-text'>
                  <div className='txt-title'>
                    Cơ hội làm việc tại <br />Nhật Bản
                  </div>
                  <div className='txt-desc'>
                    Bạn có cơ hội tham gia vào nhiều dự án lớn, sản phẩm lớn có tính ứng dụng thực tế cao, mang lại nhiều giá trị cho khách hàng, cho cộng đồng, cho xã hội, và cho chính bản thân bạn.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='sc-depart'>
          <div className='container'>
            <div className='ttl-primary'>Bạn có thể thuộc về</div>
          </div>
          <div className='box-main'>
            <div className='box-main-content'>
              <div className='item tsoft'>
                <div className='icon'>
                  <img src='/assets/images/icon-tsoft.svg' alt='TSOFT' className='h-sp' />
                  <img src='/assets/images/icon-tsoft_sp.svg' alt='TSOFT' className='h-pc' />
                </div>
                <div className='ttl'>Bộ phận TSOFT</div>
                <div className='txt'>Các dự án outsourcing trong <br />và ngoài nước</div>
              </div>
              <div className='item flatform'>
                <div className='icon'>
                  <img src='/assets/images/icon-flatform.svg' alt='flatform' className='h-sp' />
                  <img src='/assets/images/icon-flatform_sp.svg' alt='flatform' className='h-pc' />
                </div>
                <div className='ttl'>Bộ phận Platform</div>
                <div className='txt'>Phát triển công nghệ <span className='br-sp'>nền tảng</span></div>
              </div>
              <div className='item mobile'>
                <div className='icon'>
                  <img src='/assets/images/icon-smartphone.svg' alt='Mobile' className='h-sp' />
                  <img src='/assets/images/icon-smartphone_sp.svg' alt='Mobile' className='h-pc' />
                </div>
                <div className='ttl'>Bộ phận Mobile</div>
                <div className='txt'>Chịu trách nhiệm về các <br />ứng dụng Mobile</div>
              </div>
              <div className='item aiot'>
                <div className='icon'>
                  <img src='/assets/images/icon-aiot.svg' alt='AIoT' className='h-sp' />
                  <img src='/assets/images/icon-aiot_sp.svg' alt='AIoT' className='h-pc' />
                </div>
                <div className='ttl'>Bộ phận AIoT</div>
                <div className='txt'>Nghiên cứu và phát triển các <br />sản phẩm A.I</div>
              </div>
              <div className='item qaqc'>
                <div className='icon'>
                  <img src='/assets/images/icon-qaqc.svg' alt='QA/QC' className='h-sp' />
                  <img src='/assets/images/icon-qaqc_sp.svg' alt='QA/QC' className='h-pc' />
                </div>
                <div className='ttl'>Bộ phận QA/QC</div>
                <div className='txt'>Đảm bảo chất lượng các sản phẩm</div>
              </div>
              <div className='item design'>
                <div className='icon'>
                  <img src='/assets/images/icon-design.svg' alt='design' className='h-sp' />
                  <img src='/assets/images/icon-design_sp.svg' alt='design' className='h-pc' />
                </div>
                <div className='ttl'>Bộ phận Design</div>
                <div className='txt'>Sáng tạo, UX,UI</div>
              </div>
              <div className='item busi'>
                <div className='icon'>
                  <img src='/assets/images/icon-busi.svg' alt='Kinh doanh' className='h-sp' />
                  <img src='/assets/images/icon-busi_sp.svg' alt='Kinh doanh' className='h-pc' />
                </div>
                <div className='ttl'>Bộ phận Kinh doanh</div>
                <div className='txt'>Tìm kiếm khách hàng, kinh doanh <br />sản phẩm</div>
              </div>
              <div className='item marketing'>
                <div className='icon'>
                  <img src='/assets/images/icon-marketing.svg' alt='marketing' className='h-sp' />
                  <img src='/assets/images/icon-marketing_sp.svg' alt='marketing' className='h-pc' />
                </div>
                <div className='ttl'>Bộ phận Truyền thông và <br />Maketing</div>
                <div className='txt'>Truyền thông, quảng bá công ty, sản phẩm</div>
              </div>
              <div className='item no-content' />
            </div>
          </div>
        </div>
        <div className='sc-vision'>
          <div className='container'>
            <div className='sc-vision-flex'>
              <div className='ttl'>
                Tầm  nhìn <br />&amp; Sứ mệnh
              </div>
              <div className='c-box'>
                <div className='box'>
                  <div className='c-ttl'>Tầm  nhìn</div>
                  <div className='c-txt'>Trở thành tập đoàn công nghệ đa lĩnh vực hàng đầu Việt Nam.</div>
                </div>
                <div className='box'>
                  <div className='c-ttl'>Sứ mệnh</div>
                  <div className='c-txt'>Tạo ra các sản phẩm, giải pháp công nghệ chất lượng, thúc đẩy quá trình chuyển đổi số toàn diện của xã hội.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='sc-map'>
          <img src='/assets/images/map-new.webp' alt='map' className='h-sp' />
          {/* <img src='/assets/images/map-new.png' alt='map' className='h-pc' /> */}
        </div>
      </div>
    </>


  );
}
